/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    h3: "h3",
    a: "a",
    p: "p",
    code: "code",
    pre: "pre",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Frameworks"), "\n", React.createElement(_components.h2, null, "JavaScript Templating Frameworks"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "http://ejs.co/"
  }, "EJS")), "\n", React.createElement(_components.p, null, "Within the server.js file the addition of ", React.createElement(_components.code, null, "server.ser( 'view engine', 'ejs');"), " will set express to search ejs templates under a views/ directory."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "import express from 'express';\nconst server = express();\n\nserver.set('view engine', 'ejs');\n\n")), "\n", React.createElement(_components.h4, null, "Includes"), "\n", React.createElement(_components.p, null, "The most basic use of an include is to call portions of html from seperate sources rather then having either all the html in one doc. Or having to repeat html code over and over in seperate instence."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "<%- include('someEJSFile') -%>\n//renders the defined ejs file\n")), "\n", React.createElement(_components.h3, null, "Liquid"), "\n", React.createElement(_components.h3, null, "Handlebars"), "\n", React.createElement(_components.h2, null, "JavaScript Web Server Frameworks"), "\n", React.createElement(_components.h3, null, "Express"), "\n", React.createElement(_components.h3, null, "Koa"), "\n", React.createElement(_components.h3, null, "Hapi.js"), "\n", React.createElement(_components.h3, null, "Sails.js"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
